<template>
  <div class='JZContainer'>
    <content-block>
      <query-form  size="small" ref='JZForm' class="content_block"  label-width="120px" @search="handleSearch" :keepOpenedRow='2' @reset='handleReset'>
        <el-form-item label="发票状态：" :span="24">
          <check-tag v-model="JZForm.status" @change="handleStatusChange">
            <check-tag-item  :value="i.value" :key="i.value" v-for="i in statusTagLists" :label="`${i.label}(${i.quantity||0})`"></check-tag-item>
          </check-tag>
        </el-form-item>
        <el-form-item label="发票号：">
            <el-input v-model.trim="JZForm.code"  placeholder="请输入发票号"></el-input>
        </el-form-item>
        <el-form-item label="发票金额：">
            <el-input v-model.trim="JZForm.name"  placeholder="请输入发票金额"></el-input>
        </el-form-item>
        <el-form-item label="录入人：">
            <el-input v-model.trim="JZForm.spec"  placeholder="请输入录入人"></el-input>
        </el-form-item>
        <el-form-item label="开票日期(起)：" prop="beginTime" >
          <span slot="label" title="开票日期(起)">
            开票日期(起)：
          </span>
          <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择开票日期(起)" value-format="yyyy-MM-dd HH:mm:ss"  v-model.trim="JZForm.beginTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="开票日期(止)：" prop="endTime" >
            <span slot="label" title="开票日期(止)">
            开票日期(止)：
          </span>
          <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择开票日期(止)" value-format="yyyy-MM-dd HH:mm:ss"  v-model.trim="JZForm.endTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="录入日期(起)：" prop="beginTime" >
          <span slot="label" title="录入日期(起)">
            录入日期(起)：
          </span>
          <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择录入日期(起)" value-format="yyyy-MM-dd HH:mm:ss"  v-model.trim="JZForm.beginTime"></el-date-picker>
        </el-form-item>
        <el-form-item label="录入日期(止)：" prop="endTime" >
            <span slot="label" title="开票日期(止)">
            录入日期(止)：
          </span>
          <el-date-picker @change='handleSearch' type="datetime"  placeholder="请选择录入日期(止)" value-format="yyyy-MM-dd HH:mm:ss"  v-model.trim="JZForm.endTime"></el-date-picker>
        </el-form-item>
      </query-form>
    </content-block>
    <content-block>
      <TableWithPagination
        :tableData="targetLists"
        ref="orderTable"
        :columnList="columnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
      ></TableWithPagination>
    </content-block>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';

export default {
  name: 'SalesInvoicingRecord',
  mixins: [InfoMixin],
  methods: {
    getTargetLists () {
      // let currentParams = this.getCurrentParams();
      // SystemSetting.getAccountLists(currentParams).then(this.getTargetListsSuccessFun);
    },
    inventoryAdjustment () {

    },
    exportTable () {

    },
    handleSelectionChange (list) {
      this.selectedLists = list || [];
    },
    handleSearch () {

    },
    handleReset () {
      this.JZForm = this.$options.data().JZForm;
      this.handleSearch();
    },
    handleStatusChange () {
      this.handleSearch();
    },
    handleDetail (info) {
      this.$router.push({
        name: 'SalesInvoicingDetail',
        query: {
          id: info.id,
        },
      });
    },
  },
  data () {
    return {
      JZForm: {
        code: '',
        name: '',
        spec: '',
        brandName: '',
        customerName: '',
        medicalFlag: '',
        beginTime: '',
        endTime: '',
        status: '',
      },
      statusTagLists: [
        {
          label: '全部发票',
          value: '',
          quantity: 0,
        },
        {
          label: '待审核',
          value: '002',
          quantity: 0,
        },
        {
          label: '已审核',
          value: '102',
          quantity: 0,
        },
        {
          label: '已删除',
          value: '103',
          quantity: 0,
        },
        {
          label: '已驳回',
          value: '104',
          quantity: 0,
        },
      ],
      targetLists: [{id: 1}],
      columnList: [
        {
          tooltip: true,
          label: '发票类型',
          minWidth: '110px',
          prop: '',
        },
        {
          tooltip: true,
          label: '发票号码',
          minWidth: '150px',
          prop: 'ledgerType',
        },
        {
          tooltip: true,
          label: '开票单位',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '税率',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票金额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票净额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票税额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '录入人员',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '录入时间',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '备注',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票时间',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '客户抬头',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '150px',
          prop: 'ledgerName',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'blue',
              },
              on: {
                click: this.handleDetail.bind(this, row),
              },
            }, '查看'));
            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .el-date-editor.el-input, /deep/ .el-date-editor.el-input__inner{
  width:100%;
}
</style>
